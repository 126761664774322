const seo = {
  title: 'OLEA',
  description: 'OLEA Collection',
  canonical: 'https://olea.art/',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://olea.art/',
    title: 'OLEA',
    description: 'OLEA',
    images: [
      {
        url: 'https://res.cloudinary.com/hmkqf8kua/image/upload/v1661980228/defaults/Screen_Shot_2022-08-31_at_5.09.09_PM_x0f0dq.png',
        width: 32,
        height: 32,
        alt: 'OLEA',
        type: 'image/png',
      },
    ],
    site_name: 'OLEA',
  },
  // twitter: {
  //   handle: '@olea_collection',
  //   site: '@olea_collection',
  //   cardType: 'summary_large_image',
  // },
};
export default seo;
